import React from 'react'
import { InTouch, IntriguingReader, Likes, WriteWithLove } from '../Illustrations'
import styles from './HowItWorks.module.scss'
import { Body, Title, TitleLevel } from '../Typography'

export const HowItWorks: React.FC = () => {
  return (
    <section className={styles['hiw-wrapper']}>
      <article className={styles['hiw-step']}>
        <Title level={TitleLevel.THREE}>1. Create a group</Title>
        <div className={styles['hiw-illustration-wrapper']}>
          <InTouch className={styles['hiw-illustration']} />
        </div>
        <Body>Create your Letters group and invite its members. Letter groups can have the most variate purposes. You can make one for your close friends, for your family, for your teammates at work, or for your book club friends.</Body>
      </article>
      <article className={styles['hiw-step']}>
        <Title level={TitleLevel.THREE}>2. Write</Title>
        <div className={styles['hiw-illustration-wrapper']}>
          <WriteWithLove className={styles['hiw-illustration']} />
        </div>
        <Body>Write whenever you feel inspired or compelled. Do it on our web app or using your email. Content matters more than anything else, and we make sure this is true by design in every aspect of the platform.</Body>
      </article>
      <article className={styles['hiw-step']}>
        <Title level={TitleLevel.THREE}>3. Read</Title>
        <div className={styles['hiw-illustration-wrapper']}>
          <IntriguingReader className={styles['hiw-illustration']} />
        </div>
        <Body>Absorb everyone else's words. The energy you put into your writing should be the same you'll put into your reading. What you read from your fellow will fuel your following writing.</Body>
      </article>
      <article className={styles['hiw-step']}>
        <Title level={TitleLevel.THREE}>4. Feel the difference</Title>
        <div className={styles['hiw-illustration-wrapper']}>
          <Likes className={styles['hiw-illustration']} />
        </div>
        <Body>Letters is different from other platforms because it will respect a set cadence instead of instantly sharing your updates with everyone. Your writing stays in a hold state until the first day of the month when we send it to the whole group. You can change, add or remove content from it during this period, and everyone else can do the same.</Body>
        <Body>This cadence is not a random constrain. It allows for more reflective output and less invasive input. You know when you will hear from others; you don't have to keep reloading an app. You know you can put more thought into every word. You're not fighting for attention, and you can go deep.</Body>
      </article>
      <footer>
        <Body>Do you want to give it a try? <a href="mailto:vini@letters.group?subject=I want to join">Send a quick email</a> to <b>vini@letters.group</b> explaining why you'd like to join and get an early user invite.</Body>
      </footer>
    </section>
  )
}
