import { ContentTemplate } from '../components/ContentTemplate'
import { Body, Title, TitleLevel } from '../components/Typography'
import { FullScreenHero } from '../components/FullScreenHero'
import { HowItWorks } from '../components/HowItWorks'
import { SendingManyMessages } from '../components/Illustrations'
import styles from './index.module.scss'

export default function Home (): JSX.Element {
  return <ContentTemplate pageTitle='Home' hideNavigation={true}>
    <FullScreenHero>
      <Title level={TitleLevel.ONE}>Meaningful Conversations In The Digital Era</Title>
      <SendingManyMessages className={styles['home-illustration']} />
    </FullScreenHero>
    <br />
    <Title level={TitleLevel.TWO}>Welcome to Letters</Title>
    <Body>
      A platform for effective <b>long-form slow-paced</b> communication on the internet. If you are looking to have <b>more in-depth and more meaningful conversations</b> digitally while spending <b>less time online</b>, we are the right tool for you.
    </Body>
    <Title level={TitleLevel.TWO}>How It Works</Title>
    <Title level={TitleLevel.THREE}>A Different Kind of Newsletter</Title>
    <Body>
      Newsletters are a notoriously effective means of expression. Our platform captures their efficiency and applies it in a different context: small group communication. The result of that is <b>an exceptional tool for meaningful communication</b>. It works like this:
    </Body>

    <HowItWorks />
  </ContentTemplate>
}
