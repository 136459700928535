import React from 'react'
import styles from './Footer.module.scss'

export const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <p>2021 &copy; Letters - All Rights Reserved.</p>
    </footer>
  )
}
