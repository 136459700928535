import React from 'react'
import Head from 'next/head'
import { Header } from '../Header'
import { Footer } from '../Footer'
import styles from './ContentTemplate.module.scss'

interface ContentTemplateProps {
  pageTitle: string
  hideNavigation?: boolean
}

export const ContentTemplate: React.FC<ContentTemplateProps> = ({ children, pageTitle, hideNavigation = false }) => {
  return (
    <div className={styles.container}>
      <Head><title>{pageTitle}</title></Head>
      <main>
        <Header hideNavigation={hideNavigation} />
        <section>
          {children}
        </section>
        <Footer />
      </main>
    </div>
  )
}
